<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <div ref="modal" :class="$style.NotificationModal" class="common-modal">
      <div class="modal-header" :class="$style.header">
        <h1 class="h4" :class="$style.title">{{ $t("Уведомление") }}</h1>
        <SvgoIconClose
          :class="$style.close"
          class="icon-close"
          @click="$emit('close')"
        />
      </div>
      <div :class="$style.column">
        <div
          v-for="{ message, currency } in data.notifications"
          :key="currency"
          :class="$style.text"
        >
          <strong class="brand">{{ currency }}:</strong>
          <div class="common-text" v-html="message"></div>
        </div>
        <VButton block @click="data.action()">
          {{ $t("Продолжить") }}
        </VButton>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
type Props = {
  visible: boolean;
  data: {
    notifications?: { currency: string; message: string }[];
    action: () => void;
  };
};

defineProps<Props>();

const emit = defineEmits(["after-leave", "after-enter", "close"]);

const modal = ref(null);

onClickOutside(modal, () => {
  emit("close");
});
</script>

<style lang="scss" module>
.NotificationModal {
  position: relative;

  br {
    display: none;
  }
}

.title {
  text-align: center;
  width: 100%;
}

.header {
  margin-bottom: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.text {
  text-align: center;
  line-height: 140%;
  color: var(--text-color-6);
  white-space: break-spaces;
}

.close {
  position: absolute;
  right: 3rem;

  @include respond-to(xs) {
    right: 20px;
  }
}
</style>
