<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <div ref="modal" :class="$style.PromocodeModal" class="common-modal">
      <div class="modal-header" :class="$style.header">
        <h1 class="h4" :class="$style.title">
          {{ t("Как получить промокод?") }}
        </h1>
        <SvgoIconClose
          class="icon-close"
          @click="$emit('close')"
        ></SvgoIconClose>
      </div>
      <ul class="common-list">
        <li class="list-item">
          <span class="common-box"> 1 </span>
          <p>
            {{ t("Перейдите на мониторинг") }}
            <VLink
              variant="secondary"
              to="https://www.bestchange.ru/coinstart-exchanger.html"
              target="_blank"
            >
              BestСhange
            </VLink>
          </p>
        </li>
        <li class="list-item">
          <span class="common-box"> 2 </span>
          <p>{{ t("Оставьте положительный отзыв о CoinStart") }}</p>
        </li>
        <li class="list-item">
          <span class="common-box"> 3 </span>
          <p>
            {{
              t(
                "Подтвердите отзыв по ссылке, которая придет на почту от BestСhange",
              )
            }}
          </p>
        </li>
        <li class="list-item">
          <span class="common-box"> 4 </span>
          <p>
            {{ t("Если сделали все правильно, промокод появится на странице") }}
            <VLink
              variant="secondary"
              @click="onPromocodesClick"
              target="_blank"
            >
              {{ t("промокодов") }}
            </VLink>
            {{ t("в личном кабинете") }}
          </p>
        </li>
      </ul>
      <p class="common-text" :class="$style.text">
        {{ t("Обратите внимание") }}
      </p>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import ModalWrapper from "~/components/layout/modals/ModalWrapper.vue";
import { useUserStore } from "~/stores/useUserStore";
import LoginModal from "~/components/layout/modals/LoginModal.vue";

defineProps({
  visible: Boolean,
});

const { t } = useI18n({
  useScope: "local",
});

const emit = defineEmits(["after-leave", "after-enter", "close"]);

const modal = ref(null);

onClickOutside(modal, () => {
  emit("close");
});

const { $modal } = useNuxtApp();

const localePath = useLocalePath();

function onPromocodesClick() {
  if (useUserStore().isLogged) {
    useRouter().push(localePath("/account/promocode"));
  } else {
    $modal.open(markRaw(LoginModal), {});
  }
}
</script>

<i18n lang="json">
{
  "ru": {
    "Как получить промокод?": "Как получить промокод?",
    "Перейдите на мониторинг": "Перейдите на мониторинг",
    "Оставьте положительный отзыв о CoinStart": "Оставьте положительный отзыв о CoinStart",
    "Подтвердите отзыв по ссылке, которая придет на почту от BestСhange": "Подтвердите отзыв по ссылке, которая придет на почту от BestСhange",
    "Если сделали все правильно, промокод появится на странице": "Если сделали все правильно, промокод появится на странице",
    "Обратите внимание": "Обратите внимание, что почта, указанная в заявке, и почта, указанная при оставление отзыва, должны совпадать.",
    "промокодов": "промокодов",
    "в личном кабинете": "в личном кабинете"
  },
  "en": {
    "Как получить промокод?": "How to get a promo code?",
    "Перейдите на мониторинг": "Go to",
    "Оставьте положительный отзыв о CoinStart": "Leave a positive review about CoinStart",
    "Подтвердите отзыв по ссылке, которая придет на почту от BestСhange": "Confirm the review using the link that will be sent to the mail from BestShange",
    "Если сделали все правильно, промокод появится на странице": "If you have done everything correctly, the promo code will appear on",
    "Обратите внимание": "Please note that the mail specified in the application and the mail specified when leaving a review must match.",
    "промокодов": "promo codes",
    "в личном кабинете": "page in your personal account"
  }
}
</i18n>

<style lang="scss" module>
.PromocodeModal {
  max-width: 55rem;
}

.header {
  margin-bottom: 2rem;
}

.title {
  text-align: center;
  width: 100%;
}

.text {
  color: var(--text-color-8);
}
</style>
