<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <form
      ref="modal"
      class="common-modal"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="handleSubmit"
    >
      <div class="modal-header" :class="$style.header">
        <h1 class="h4" :class="$style.title">
          {{ t("Восстановление пароля") }}
        </h1>
        <SvgoIconClose class="icon-close" @click="$emit('close')" />
      </div>
      <transition name="fade-fast">
        <p v-if="errors?.nonFieldErrors" :class="$style.error">
          {{ errors.nonFieldErrors }}
        </p>
      </transition>
      <div :class="$style.input">
        <InputPassword
          v-model="password"
          :placeholder="t('Пароль')"
          required
          :error="errors.password as string"
          @input="errors.password = ''"
        />
        <InputPassword
          v-model="passwordAgain"
          :placeholder="t('Повторите пароль')"
          required
          :error="errors.passwordAgain as string"
          @input="errors.passwordAgain = ''"
        />
      </div>
      <VButton
        type="submit"
        :class="$style.recover"
        block
        :label="t('Восстановить')"
      />
    </form>
  </ModalWrapper>
</template>

<script setup lang="ts">
import MessageModal from "~/components/layout/modals/MessageModal.vue";
import { userApi } from "~/utils/api/user";
import { useFormErrors } from "~/composables/useFormErrors";

const props = defineProps<{
  visible: boolean;
  data: {
    code: any;
  };
}>();

const { t } = useI18n({
  useScope: "local",
});

const emit = defineEmits(["after-leave", "after-enter", "close"]);

const modal = ref(null);

const password = ref("");
const passwordAgain = ref("");

const { errors, setErrors } = useFormErrors();
const { $modal } = useNuxtApp();
async function handleSubmit() {
  try {
    const data = {
      password: password.value,
      passwordAgain: passwordAgain.value,
    };
    const response = await userApi.resetAccount(props.data.code, data);
    if (response.result === "ok") {
      $modal.open(markRaw(MessageModal), {
        title: t("Пароль успешно изменен"),
        message: t("Теперь вы можете войти в свой аккаунт"),
      });
    }
  } catch (error: any) {
    setErrors(error.data);
  }
}

onClickOutside(modal, () => {
  emit("close");
});
</script>

<style lang="scss" module>
.title {
  text-align: center;
  width: 100%;
}

.header {
  margin-bottom: 1rem;
}

.input {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.recover {
  margin-bottom: 1.5rem;

  @include respond-to(xs) {
    margin-bottom: 10px;
  }
}

.error {
  margin-bottom: 1.2rem;
  color: $brand-red;
}
</style>
