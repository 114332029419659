<template>
  <div :class="$style.VInputThousands" @click="autoFocus">
    <input
      v-bind="$attrs"
      ref="input"
      type="text"
      :value="splittedValue"
      :class="$style.input"
      :disabled="disabled"
      @input="onInput"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script setup lang="ts">
interface VInputThousandsProps {
  modelValue: string | number | null;
  disabled?: boolean;
  delimiter?: string;
  decimalMark?: string;
  positiveOnly?: boolean;
  integerOnly?: boolean;
  decimalCount?: number;
  defaultValue?: string | number;
}

const props = withDefaults(defineProps<VInputThousandsProps>(), {
  disabled: false,
  delimiter: " ",
  decimalMark: ".",
  positiveOnly: true,
  integerOnly: false,
  decimalCount: 2,
  defaultValue: "",
});

const emit = defineEmits(["update:modelValue", "blur", "change"]);

const input = ref<HTMLInputElement | null>(null);
const isFocused = ref(false);

const autoFocus = () => {
  input.value?.focus();
};

const onFocus = () => {
  isFocused.value = true;
};

const onBlur = (event: FocusEvent) => {
  isFocused.value = false;
  emit("blur", event);
};

const splitThousands = (value: string | number | null) => {
  // console.log(value);
  // if (typeof value !== "number" || isNaN(value)) {
  //   return "";
  // }
  if (value === null) {
    return "0";
  }

  let partDecimal = "";
  let parts;

  value = value.toString();
  value = value
    .replace(/[A-Za-z]/g, "")
    .replace(props.decimalMark, "M")
    .replace(/[^\dM-]/g, "")
    .replace(/^-/, "N")
    .replace(/-/g, "")
    .replace("N", props.positiveOnly ? "" : "-")
    .replace("M", props.decimalMark);

  if (props.integerOnly) {
    value = value.replace(/[^0-9]/g, "");
  }
  const partSign = value.slice(0, 1) === "-" ? "-" : "";
  const partSignAndPrefix = partSign;
  let partInteger = value;

  if (value.includes(props.decimalMark)) {
    parts = value.split(props.decimalMark);
    partDecimal = props.decimalMark + parts[1].slice(0, props.decimalCount);
    partInteger = parts[0];
  }

  if (partSign === "-") {
    partInteger = partInteger.slice(1);
  }

  partInteger = partInteger.replace(
    /(\d)(?=(\d{3})+$)/g,
    `$1${props.delimiter}`,
  );

  return (
    partSignAndPrefix +
    partInteger.toString() +
    (props.decimalCount > 0 ? partDecimal.toString() : "")
  );
};

const splittedValue = ref(splitThousands(props.modelValue));
const cleanValue = computed(() => {
  return Number(splittedValue.value.split(props.delimiter).join(""));
});

const onInput = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  let endPos = target.selectionEnd || 0;
  const oldValue = target.value;
  const newValue = splitThousands(target.value);
  target.value = newValue;
  splittedValue.value = newValue;

  nextTick(() => {
    endPos = getNextCursorPosition(endPos, oldValue, newValue, props.delimiter);
    setCursor(target, endPos);
    emit("update:modelValue", cleanValue.value);
  });
};

const onChange = () => {
  if (splittedValue.value.length > 1 && splittedValue.value[0] === "0") {
    splittedValue.value = splitThousands(
      cleanValue.value.toString().replace(/^(-)?0+(?=\d)/, "$1"),
    );
  }
  if (!splittedValue.value || splittedValue.value === "0") {
    splittedValue.value = splitThousands(props.defaultValue);
  }

  nextTick(() => {
    emit("change", cleanValue.value);
  });
};

const getNextCursorPosition = (
  prevPos: number,
  oldValue: string,
  newValue: string,
  delimiter: string,
) => {
  return oldValue.length === prevPos
    ? newValue.length
    : getPositionOffset(prevPos, oldValue, newValue, delimiter);
};

const getPositionOffset = (
  prevPos: number,
  oldValue: string,
  newValue: string,
  delimiter: string,
) => {
  const oldRawValue = stripDelimiters(oldValue.slice(0, prevPos), delimiter);
  const newRawValue = stripDelimiters(newValue.slice(0, prevPos), delimiter);
  const lengthOffset = oldRawValue.length - newRawValue.length;
  return lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0;
};

const stripDelimiters = (value: string, delimiter: string) => {
  const delimiterRE = delimiter
    ? new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "g")
    : "";
  return value.replace(delimiterRE, "");
};

const setCursor = (el: EventTarget, position: number) => {
  const setSelectingRange = () => {
    (el as HTMLInputElement).setSelectionRange(position, position);
  };
  if (el === document.activeElement) {
    setSelectingRange();
    setTimeout(setSelectingRange, 1);
  }
};

watch(
  () => props.modelValue,
  (value) => {
    if (value === cleanValue.value) {
      return;
    }
    splittedValue.value = splitThousands(value);
  },
);
</script>

<style lang="scss" module>
.VInputThousands {
  display: flex;
  align-items: center;
  height: 4.8rem;
  color: $brand-blue;
  cursor: text;

  @include respond-to(xs) {
    height: 31px;
  }
}

.input {
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: inherit;
  color: inherit;
  border: none;
  outline: none;
  font-size: 3.6rem;
  line-height: 1;
  font-weight: 450;

  &::placeholder {
    color: currentcolor;
  }

  @include respond-to(xs) {
    font-size: 24px;
    padding: 0;
  }
}
</style>
