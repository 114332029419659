<template>
  <form
    :class="$style.HomeCalculator"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="handleSubmit"
  >
    <transition name="fade-fast">
      <HomeCalculatorTechMode v-if="isTechMode" :time="time" />
    </transition>
    <div :class="$style.inputs">
      <HomeCalculatorInput
        :value="fromValue"
        :round="round.from"
        :directions="fromDirections"
        :title="fromCurrencyData.title"
        :logo="fromCurrencyData.logo"
        :active-name="fromCurrencyData.name"
        :on-currency-change="onFromCurrencyChange"
        :label="t('Отдаете')"
        @input="handleInputFrom"
        @change="handleChangeFrom"
      />
      <HomeCalculatorInput
        :value="toValue"
        :round="round.to"
        :directions="toDirections"
        :title="toCurrencyData.title"
        :logo="toCurrencyData.logo"
        :active-name="toCurrencyData.name"
        :on-currency-change="onToCurrencyChange"
        :label="t('Получаете')"
        @input="handleInputTo"
        @change="handleChangeTo"
      />
      <HomeCalculatorSwap :class="$style.swap" />
    </div>
    <div :class="$style.info">
      <CalculatorCourse />
      <HomeCalculatorDiscount :value="bonus" />
      <transition name="fade-fast">
        <HomeCalculatorNetworkLoad v-if="congestion" :load="congestion.load" />
      </transition>
    </div>
    <div :class="$style.promo">
      <VInput v-model="promo" :placeholder="t('Промокод, необязательно')" />
      <VLink variant="secondary" :class="$style.link" @click="openPromoModal">
        {{ t("Как получить промокод?") }}
      </VLink>
    </div>
    <VButton type="submit" block :class="$style.btn">
      {{ t("Перейти к вводу реквизитов") }}
    </VButton>
  </form>
</template>

<script setup lang="ts">
import PromocodeModal from "~/components/layout/modals/PromocodeModal.vue";
import type { Direction } from "~/types/calculator";
import useCalculatorData from "~/composables/calculator/useCalculatorData";
import useDirectionsCalculator from "~/composables/calculator/useDirectionsCalculator";
import useCalculatorValues from "~/composables/calculator/useCalculatorValues";
import NotificationModal from "~/components/layout/modals/NotificationModal.vue";

interface HomeCalculatorProps {
  isTechMode?: boolean;
  directions: Direction[];
  time?: number;
}

const { t } = useI18n({
  useScope: "local",
});

const promo = ref<string>("");

const props = withDefaults(defineProps<HomeCalculatorProps>(), {
  isTechMode: false,
  time: 0,
});

const {
  toCurrencyData,
  fromCurrencyData,
  fromDirections,
  toDirections,
  bonus,
  congestion,
  notifications,
} = await useCalculatorData(props.directions);
const { onToCurrencyChange, onFromCurrencyChange } = useDirectionsCalculator();
const {
  fromValue,
  toValue,
  round,
  handleInputFrom,
  handleChangeFrom,
  handleInputTo,
  handleChangeTo,
} = useCalculatorValues();

const { $modal } = useNuxtApp();

function openPromoModal() {
  $modal.open(markRaw(PromocodeModal));
}

const localeRoute = useLocaleRoute();

async function handleSubmit() {
  if (!fromValue.value || !toValue.value) return;
  const route = localeRoute("claim");
  if (!route) return;
  useCalculatorStore().setPromocode(promo.value);
  if (notifications.value?.length) {
    $modal.open(markRaw(NotificationModal), {
      notifications: notifications.value,
      action: () => {
        useRouter().push(route);
      },
    });
  } else {
    await useRouter().push(route);
  }
}
</script>

<i18n lang="json">
{
  "ru": {
    "Отдаете": "Отдаете",
    "Получаете": "Получаете",
    "Промокод, необязательно": "Промокод, необязательно",
    "Как получить промокод?": "Как получить промокод?",
    "Перейти к вводу реквизитов": "Перейти к вводу реквизитов"
  },
  "en": {
    "Отдаете": "Send",
    "Получаете": "You receive",
    "Промокод, необязательно": "Promo code, optional",
    "Как получить промокод?": "How do I get a promo code?",
    "Перейти к вводу реквизитов": "Go to entering the details"
  }
}
</i18n>

<style lang="scss" module>
.HomeCalculator {
  padding: 2rem;
  background-color: var(--main-block-bg);
  box-shadow: 0 4px 50px 4px rgba(12 19 29 / 8%);
  border-radius: $border-radius-l;
  max-width: 59.6rem;
  width: 100%;
  overflow: hidden;

  @include respond-to(sm) {
    max-width: 100%;
    height: auto;
  }

  @include respond-to(xs) {
    padding: 5px;
    border-radius: $border-radius-m;
  }
}

.inputs {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.swap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1.4rem;
  padding: 0 2rem;

  @include respond-to(xs) {
    margin-top: 10px;
  }
}

.promo {
  margin-top: 1.6rem;

  @include respond-to(xs) {
    margin-top: 10px;
  }
}

.link {
  margin-left: 2rem;

  @include respond-to(xs) {
    margin-top: 5px;
  }
}

.btn {
  margin-top: 2rem;
}
</style>
