<template>
  <div :class="$style.HomeHero">
    <HomeBackground ref="background" :shapes-length="5" />
    <div class="container" :class="$style.content">
      <div :class="$style.hero">
        <HomeHeroBadge />
        <h1 :class="$style.title">
          {{ t("Обменивайте быстро, безопасно и выгодно") }}
        </h1>
        <VLink
          v-if="contestStatus"
          :class="$style.link"
          :to="localePath('/contest')"
        >
          {{ t("Участвовать в конкурсе за отзыв") }}
        </VLink>
      </div>
      <HomeCalculator
        v-if="data"
        :class="$style.calculator"
        :directions="data"
        :is-tech-mode="tech.on"
        :time="tech.time"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { calculatorApi } from "~/utils/api/calculator";

const { t } = useI18n({
  useScope: "local",
});

const localePath = useLocalePath();

const data = await calculatorApi.getDirections();

const { tech } = await useTech();

const { contestStatus } = useSettings();
</script>

<i18n lang="json">
{
  "ru": {
    "Обменивайте быстро, безопасно и выгодно": "Обменивайте быстро, безопасно и выгодно",
    "Участвовать в конкурсе за отзыв": "Участвовать в конкурсе за отзыв"
  },
  "en": {
    "Обменивайте быстро, безопасно и выгодно": "Exchange quickly, safely and profitably",
    "Участвовать в конкурсе за отзыв": "Participate in the review contest"
  }
}
</i18n>

<style lang="scss" module>
.HomeHero {
  position: relative;

  @include respond-to(sm) {
    padding-bottom: 150px;
  }

  @include respond-to(xs) {
    padding-bottom: 105px;
  }
}

.content {
  display: flex;
  min-height: calc(100vh - 64px - 80px);
  justify-content: space-between;

  @include respond-to(sm) {
    min-height: unset;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }
}

.hero {
  margin-top: 10vh;

  @include respond-to(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 66px;
  }
}

.calculator {
  position: sticky;
  top: 5rem;
  margin-top: 5vh;
  margin-bottom: 12rem;
  align-self: flex-start;

  @include respond-to(sm) {
    position: static;
    margin-top: 0;
    margin-bottom: 0;
    align-self: unset;
  }
}

.title {
  max-width: 53.1rem;
  margin-top: 2rem;
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 450;
  line-height: 90%;
  letter-spacing: -1.28px;
  cursor: default;

  @include respond-to(sm) {
    max-width: 780px;
    text-align: center;
  }

  @include respond-to(xs) {
    max-width: 100%;
    font-size: 28px;
  }
}

.link {
  margin-top: 3rem;

  @include respond-to(sm) {
    margin-top: 20px;
  }

  @include respond-to(xs) {
    margin-top: 10px;
  }
}
</style>
