<template>
  <div :class="$style.HomeHeroBadge">
    <span :class="$style.dot"></span>
    <span :class="$style.text"> {{ t("Работаем") }} 24/7 </span>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: "local",
});
</script>

<i18n lang="json">
{
  "ru": {
    "Работаем": "Работаем"
  },
  "en": {
    "Работаем": "We work"
  }
}
</i18n>

<style lang="scss" module>
.HomeHeroBadge {
  display: inline-flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 1.6rem;
  border-radius: $border-radius-l;
  background-color: var(--main-block-bg);

  @include respond-to(xs) {
    padding: 10px 12px;
    gap: 10px;
  }
}

.dot {
  width: 8px;
  height: 8px;
  background-color: $brand-green;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(34 207 82 / 20%);
  animation: blink 3.5s infinite;
}

.text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 130%;
  color: var(--text-color-8);

  @include respond-to(xs) {
    font-size: 12px;
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
</style>
