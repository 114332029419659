<template>
  <div :class="$style.HomeReviews">
    <HomeBackground :class="$style.background" :shapes-length="3" />
    <div class="container">
      <div :class="$style.content">
        <div :class="$style.top">
          <h1 class="h1">
            {{ t("Более") }}
            <span :class="$style.blue">{{ reviewsCount }}</span>
            {{ t("отзывов") }}
          </h1>
          <div :class="$style.link">
            <VLink
              target="_blank"
              to="https://www.bestchange.ru/coinstart-exchanger.html"
            >
              {{ t("Смотреть все отзывы") }}
            </VLink>
          </div>
        </div>
        <div v-if="!pending" :class="$style.reviews">
          <ReviewItem
            v-for="item in reviews"
            :key="item.name"
            :name="item.name"
            :review="item.review"
            :date="item.dateTime"
          />
        </div>
        <div v-else :class="$style.reviews">
          <div :class="$style.review"></div>
          <div :class="$style.review"></div>
          <div :class="$style.review"></div>
          <div :class="$style.review"></div>
        </div>
        <div :class="$style.mobile">
          <VLink
            target="_blank"
            to="https://www.bestchange.ru/coinstart-exchanger.html"
          >
            {{ t("Смотреть все отзывы") }}
          </VLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { pagesApi } from "~/utils/api/pages";

const { t } = useI18n({
  useScope: "local",
});

const { data, pending } = await pagesApi.fetchReviews();
const reviews = computed(() => {
  return data.value?.reviews.slice(0, 4) ?? [];
});
const reviewsCount = computed(() => {
  if (!data.value?.summaryCount) return "35 500";
  return (Math.floor(data.value?.summaryCount / 100) * 100)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
});
</script>

<i18n lang="json">
{
  "ru": {
    "Более": "Более",
    "отзывов": "отзывов",
    "Смотреть все отзывы": "Смотреть все отзывы"
  },
  "en": {
    "Более": "More than",
    "отзывов": "reviews",
    "Смотреть все отзывы": "See all reviews"
  }
}
</i18n>

<style lang="scss" module>
.HomeReviews {
  position: relative;
  background-color: var(--main-block-bg);
  border-radius: 4rem 4rem 0 0;
}

.background {
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  padding-top: 9rem;
  padding-bottom: 9rem;

  @include respond-to(xs) {
    padding-top: 40px;
    padding-bottom: 86px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(xs) {
    justify-content: center;
  }
}

.reviews {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 0.8rem;

  @include respond-to(sm) {
    gap: 5px;
  }

  @include respond-to(xs) {
    flex-direction: column;
  }
}

.blue {
  color: $brand-blue;
}

.link {
  @include respond-to(xs) {
    display: none;
  }
}

.mobile {
  display: none;

  @include respond-to(xs) {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    text-align: center;
  }
}

.review {
  flex-grow: 1;
  padding: 2rem;
  border-radius: $border-radius-l;
  background-color: var(--primary-block-bg);
  height: 280px;

  @include respond-to(sm) {
    height: 310px;
  }

  @include respond-to(xs) {
    height: 130px;
  }
}
</style>
