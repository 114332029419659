<template>
  <NuxtLink
    :to="localePath('/discounts')"
    :class="$style.HomeCalculatorDiscount"
  >
    <div :class="$style.icon">&#x3f;</div>
    <div :class="$style.title">{{ t("Скидка") }}</div>
    <div :class="$style.discount">{{ value }}%</div>
  </NuxtLink>
</template>

<script setup lang="ts">
defineProps<{ value: number }>();

const { t } = useI18n({
  useScope: "local",
});
const localePath = useLocalePath();
</script>

<i18n lang="json">
{
  "ru": {
    "Скидка": "Скидка"
  },
  "en": {
    "Скидка": "Discount"
  }
}
</i18n>

<style lang="scss" module>
.HomeCalculatorDiscount {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.6rem;
  color: var(--text-color-4);
  background-color: var(--primary-block-bg);
  border-radius: 50%;
  cursor: pointer;
}

.title {
  margin-left: 8px;
  color: var(--text-color-4);

  @include respond-to(xs) {
    font-size: 12px;
  }
}

.discount {
  margin-left: auto;
  color: var(--text-color-8);

  @include respond-to(xs) {
    font-size: 14px;
  }
}
</style>
