import { calculatorApi } from "~/utils/api/calculator";

interface Tech {
  time: number;
  on: boolean;
}

export default async function useTech() {
  const tech = ref<Tech>({ time: 0, on: false });

  async function getTech() {
    try {
      const response = await calculatorApi.fetchTech();
      tech.value = response.technicalMode;
    } catch (error) {
      console.error(error);
    }
  }

  useIntervalFn(async () => {
    await getTech();
  }, 10000);

  await getTech();

  return {
    tech,
  };
}
