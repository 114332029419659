<template>
  <div :class="$style.HomeCalculatorInput" @click.self="handleFocusInput">
    <div :class="$style.title">{{ label }}</div>
    <div :class="$style.input">
      <VInputThousands
        ref="inputRef"
        :model-value="value"
        :decimal-count="round"
        @update:model-value="$emit('input', $event)"
        @change="$emit('change', $event)"
      />
      <HomeCalculatorDirection
        :title="title"
        :logo="logo"
        @click="openDirectionsModal"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DirectionWithId } from "~/types/calculator";
import VInputThousands from "~/components/ui/input/VInputThousands.vue";
import DirectionsModal from "~/components/layout/modals/DirectionsModal.vue";

const inputRef = ref<InstanceType<typeof VInputThousands> | null>(null);

interface HomeCalculatorInputProps {
  directions: DirectionWithId[];
  title: string;
  logo: string;
  activeName: string;
  onCurrencyChange: (id: number) => void;
  value: number | null;
  round: number;
  label: string;
}

const props = defineProps<HomeCalculatorInputProps>();

const handleFocusInput = () => {
  if (!inputRef.value) return;
  const input = inputRef.value?.$refs?.input as HTMLInputElement;
  input.focus();
};
const { $modal } = useNuxtApp();

function openDirectionsModal() {
  $modal.open(markRaw(DirectionsModal), {
    directions: props.directions,
    activeName: props.activeName,
    onCurrencyChange: props.onCurrencyChange,
  });
}

defineEmits(["input", "change"]);
</script>

<style lang="scss" module>
.HomeCalculatorInput {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 1.6rem 1.6rem 1rem 2rem;
  border-radius: $border-radius-s;
  background-color: var(--primary-block-bg);
  cursor: text;

  @include respond-to(xs) {
    padding: 15px 15px 15px 20px;
  }
}

.title {
  width: fit-content;
  color: var(--text-color-4);

  @include respond-to(xs) {
    font-size: 14px;
  }
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
