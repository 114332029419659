<template>
  <div :class="$style.HomePage">
    <HomeHero ref="heroRef" />
    <HomeReviews />
  </div>
</template>

<script setup lang="ts">
import HomeHero from "~/components/pages/home/HomeHero.vue";
import RecoverModal from "~/components/layout/modals/RecoverModal.vue";

const route = useRoute();
const { query } = route;
const { $modal, $gsap: gsap } = useNuxtApp();

if (query?.ref) {
  useCalculatorStore().setRefferal(query?.ref as string);
}

onMounted(async () => {
  startParallaxAnimation();

  if (query?.restore) {
    $modal.open(markRaw(RecoverModal), { code: query?.restore as string });
  }
  if (query?.activate) {
    await useAuth().activate(query?.activate as string);
  }
});

const heroRef = ref<InstanceType<typeof HomeHero> | null>(null);

const startParallaxAnimation = () => {
  if (!heroRef.value) return;
  gsap.to(heroRef.value.$refs.background.$el, {
    yPercent: 15,
    scrollTrigger: {
      start: "top top",
      end: "bottom bottom",
      pin: true,
      pinSpacing: false,
      scrub: 0.8,
    },
  });
};
</script>

<style lang="scss" module>
.HomePage {
  position: relative;
}
</style>
