<template>
  <div :class="$style.CalculatorNetworkLoad">
    <div :class="$style.icon">&#x3f;</div>
    <div :class="$style.title">{{ t("Загруженность сети BTC") }}</div>
    <div :class="$style.load">
      <span
        v-for="index in 4"
        :key="index"
        :style="getBackgroundColor(index)"
      ></span>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: "local",
});

const { load } = defineProps({
  load: {
    type: Number,
    default: 0,
  },
});

const loadColors = ["#11B740", "#F2E90B", "#F0950D", "#DE1609"];

function getBackgroundColor(index: number) {
  if (index <= load) {
    return {
      backgroundColor: loadColors[load - 1],
    };
  }
}
</script>

<i18n lang="json">
{
  "ru": {
    "Загруженность сети BTC": "Загруженность сети BTC"
  },
  "en": {
    "Загруженность сети BTC": "BTC network load"
  }
}
</i18n>

<style lang="scss" module>
.CalculatorNetworkLoad {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.6rem;
  color: var(--text-color-4);
  background-color: var(--primary-block-bg);
  border-radius: 50%;
  cursor: pointer;
}

.title {
  margin-left: 8px;
  color: var(--text-color-4);

  @include respond-to(xs) {
    font-size: 12px;
  }
}

.load {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-left: auto;

  span {
    display: inline-block;
    width: 16px;
    height: 8px;
    border-radius: 1rem;
    background: var(--congestion-pill-bg);
  }
}
</style>
