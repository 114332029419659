<template>
  <div :class="$style.HomeBackground">
    <TriangleShape
      v-for="(shape, index) in shapesLength"
      ref="shapes"
      :key="shape"
      :style="shapesStyles[index]"
      :class="$style.shape"
    />
  </div>
</template>

<script setup lang="ts">
import TriangleShape from "~/components/pages/home/TriangleShape.vue";

const props = defineProps<{ shapesLength: number }>();

onMounted(() => {
  startAnimation();
});

const shapes = ref<InstanceType<typeof TriangleShape>[] | null>([]);
const shapesStyles = useState(`shapes-${props.shapesLength}`, () =>
  Array.from({ length: props.shapesLength }, () => getRandomStyles()),
);
const { $gsap: gsap } = useNuxtApp();

function generateRandomParams() {
  return {
    x: () => gsap.utils.random(200, 100, 5),
    y: () => gsap.utils.random(200, 100, 5),
    scale: () => gsap.utils.random(0.5, 1.5),
    rotate: () => gsap.utils.random(-360, 360, 5),
    duration: () => gsap.utils.random(30, 50, 5),
    ease: "power1.inOut",
    opacity: () => gsap.utils.random(0, 0.8, 0.1),
  };
}

function startAnimation() {
  if (!shapes.value) return;
  shapes.value.forEach((shape) => {
    const params = generateRandomParams();
    gsap.to(shape.$el, {
      ...params,
      repeat: -1,
      repeatRefresh: true,
    });
  });
}

function getRandomSizeStyles(min = 90, max = 360) {
  const randomSize = Math.floor(Math.random() * (max - min + 1) + min);
  return {
    width: `${randomSize}px`,
    height: `${randomSize}px`,
  };
}

function getRandomPositionStyles(min = 1, max = 90) {
  const randomX = Math.floor(Math.random() * (max - min + 1) + min);
  const randomY = Math.floor(Math.random() * (max - min + 1) + min);
  return {
    top: `${randomY}%`,
    left: `${randomX}%`,
  };
}

function getRandomOpacity() {
  return Math.random() * 0.8;
}

function getRandomStyles() {
  return {
    ...getRandomSizeStyles(),
    ...getRandomPositionStyles(),
    opacity: getRandomOpacity(),
  };
}

onBeforeUnmount(() => {
  if (!shapes.value) return;
  shapes.value.forEach((shape) => {
    gsap.killTweensOf(shape.$el);
  });
});
</script>

<style lang="scss" module>
.HomeBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
}

.shape {
  position: absolute;
}
</style>
