<template>
  <transition name="fade-fast">
    <button
      v-show="isSwapAvailable"
      :class="[
        $style.HomeCalculatorSwap,
        { [$style.disabled]: !isSwapAvailable },
      ]"
      type="button"
      @click="swap"
    >
      <SvgoIconSwap :class="$style.icon" />
    </button>
  </transition>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { calculatorApi } from "~/utils/api/calculator";
import useDirectionsCalculator from "~/composables/calculator/useDirectionsCalculator";

const store = useCalculatorStore();
const { fromCurrencyId, toCurrencyId } = storeToRefs(store);
const isSwapAvailable = ref(false);

watch(
  [fromCurrencyId, toCurrencyId],
  async () => {
    await checkSwap();
  },
  { immediate: true },
);

async function checkSwap() {
  try {
    if (!fromCurrencyId.value || !toCurrencyId.value) return;
    if (fromCurrencyId.value === toCurrencyId.value) return;
    await calculatorApi.getPairData({
      from: toCurrencyId.value,
      to: fromCurrencyId.value,
    });
    isSwapAvailable.value = true;
  } catch (e) {
    isSwapAvailable.value = false;
  }
}

function swap() {
  if (!isSwapAvailable.value) return;
  if (!fromCurrencyId.value || !toCurrencyId.value) return;
  useDirectionsCalculator().onCurrenciesChange(
    toCurrencyId.value,
    fromCurrencyId.value,
  );
}
</script>

<style lang="scss" module>
.HomeCalculatorSwap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 6px;
  background-color: var(--main-block-bg);
  cursor: pointer;
  transition: all $base-transition;

  &:hover {
    background-color: var(--primary-block-bg-hover);

    .icon {
      color: var(--text-main);
    }
  }

  &:active {
    background-color: $base-150;

    .icon {
      color: $brand-blue;
    }
  }

  .disabled {
    pointer-events: none;
  }
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  color: var(--text-color-4);
  transition: all $base-transition;
}

.disabled {
  cursor: not-allowed;
}
</style>
